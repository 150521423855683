import React from 'react';
import cn from 'classnames';
import BaseLink from '../BaseLink/BaseLink';
import Button from '../Button/Button';
import * as styles from './GDPRBanner.module.scss';

function GDPRBanner({ onAccept, onManage, show }) {
  return (
    <div className={cn(styles.wrapper, show && styles.wrapper_show)}>
      <div className={styles.banner}>
        <p className={styles.banner__text}>
          By clicking on "Accept", you're agreeing to our{' '}
          <BaseLink href="https://iterative.notion.site/Privacy-Cookie-Policy-edbce9b3b3d14f26950b7dca617b2033">
            privacy and cookie policy
          </BaseLink>
          .
        </p>
        <Button
          onClick={() => onAccept()}
          className={styles.banner__button}
          type="light-blue"
        >
          Accept
        </Button>
        <Button
          onClick={() => onManage()}
          className={styles.banner__button}
          type="white"
        >
          Options
        </Button>
      </div>
    </div>
  );
}

export default GDPRBanner;
