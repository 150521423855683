import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './Button.scss';

export default function Button({
  onClick,
  type = 'blue-gradient',
  children,
  size = 'default',
  disabled,
  typeAttr = 'button',
  className,
  buttonProps,
}) {
  return (
    <button
      type={typeAttr}
      disabled={disabled}
      className={cn(
        'button',
        `button_size_${size}`,
        `button_color_${type}`,
        className
      )}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'blue-gradient',
    'orange-gradient',
    'light-blue',
    'black',
    'white',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'default']),
  typeAttr: PropTypes.oneOf(['submit', 'reset', 'button']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  buttonProps: PropTypes.object,
};
