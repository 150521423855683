import React, { createContext, useEffect, useState } from 'react';
import safeLocalStorage from '../../../utils/safeLocalStorage';

export const GDPRContext = createContext({});

export const GDPRProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [hasUserConsented, setHasUserConsented] = useState(false);
  const [preferences, setPreferences] = useState({
    functional: false,
    analytics: false,
  });

  useEffect(() => {
    const consent = safeLocalStorage.getItem('has-user-consented');
    const opts = safeLocalStorage.getItem('user-consent-preferences');
    if (consent) {
      setHasUserConsented(true);
      const optsObj = JSON.parse(opts);
      setPreferences(optsObj);
    } else {
      setHasUserConsented(false);
    }
    setLoading(false);
  }, []);

  const setUserPreferences = (opts = { functional: true, analytics: true }) => {
    setPreferences(opts);
    safeLocalStorage.setItem('user-consent-preferences', JSON.stringify(opts));
    setHasUserConsented(true);
    safeLocalStorage.setItem('has-user-consented', true);
  };

  const updateUserPreferences = (newOpts) => {
    const opts = { ...preferences, ...newOpts };
    setPreferences(opts);
    safeLocalStorage.setItem('user-consent-preferences', JSON.stringify(opts));
  };

  return (
    <GDPRContext.Provider
      value={{
        hasUserConsented,
        setUserPreferences,
        updateUserPreferences,
        userPreferences: preferences,
        loading,
      }}
    >
      {children}
    </GDPRContext.Provider>
  );
};
