const IS_SUPPORTED = (() => {
  try {
    const key = 'localstoragecheck';
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
})();

const safeLocalStorage = {
  clear: () => IS_SUPPORTED && localStorage.clear(),
  getItem: (key) => IS_SUPPORTED && localStorage.getItem(key),
  setItem: (key, value) => IS_SUPPORTED && localStorage.setItem(key, value),
  removeItem: (key) => IS_SUPPORTED && localStorage.removeItem(key),
};

export default safeLocalStorage;
