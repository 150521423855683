import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import SEO from '../SEO';
import Analytics from '../Analytics';
import GDPR from '../GDPR/GDPR';
import { GDPRProvider } from '../GDPR/Provider';
import './Layout.scss';

export default function Layout({ children, title }) {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('utm_source')) {
      sessionStorage.setItem('google_ads', 'true');
    }
  }, []);

  return (
    <GDPRProvider>
      <div className="layout">
        <SEO title={title} />
        {process.env.NODE_ENV !== 'development' && <Analytics />}
        <HubspotProvider>
          <div className="layout__container">
            <GDPR />
            {children}
          </div>
        </HubspotProvider>
      </div>
    </GDPRProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
