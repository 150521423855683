import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import ButtonLink from '../Button/ButtonLink';
import { career__text } from './Career.module.scss';

export const careerUrl =
  'https://iterative.notion.site/Iterative-ai-is-Hiring-852cb978129645e1906e2c9a878a4d22';

export default function Career() {
  return (
    <section id="career" className="career">
      <SectionTitle title="Careers">
        <p class={career__text}>
          We value great collaboration and communication skills, both among
          internal teams and in how we interact with our users. We take care to
          balance and be responsive to the needs of our open source community as
          well as our enterprise-level customers.
        </p>

        <ButtonLink
          href={careerUrl}
          size="medium"
          type="black"
          target="_blank"
          rel="noopener noreferrer"
        >
          See open positions
        </ButtonLink>
      </SectionTitle>
    </section>
  );
}
