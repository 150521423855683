import { useEffect } from 'react';

const useOnEscape = (handler, cleanUp = true) => {
  useEffect(() => {
    const listener = (event) => {
      if (event.key === 'Escape') {
        handler(event);
        if (cleanUp) document.removeEventListener('keyup', listener);
      }
    };

    document.addEventListener('keyup', listener);

    return () => {
      document.removeEventListener('keyup', listener);
    };
  }, [handler, cleanUp]);
};
export default useOnEscape;
