import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './Title.scss';

export default function Title({ size, title, className, sizeStyle = size }) {
  const classes = cn('title', `title_size_${sizeStyle}`, className);
  if (size === 'xs') {
    return <h4 className={classes}>{title}</h4>;
  } else if (size === 's') {
    return <h3 className={classes}>{title}</h3>;
  } else if (size === 'm') {
    return <h2 className={classes}>{title}</h2>;
  }
  return <h1 className={classes}>{title}</h1>;
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  className: PropTypes.string,
  sizStyle: PropTypes.oneOf(['xs', 's', 'm', 'l']),
};
