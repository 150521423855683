import React, { useState } from 'react';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import * as styles from './GDPRPopup.module.scss';
import BaseLink from '../BaseLink/BaseLink';
import Button from '../Button/Button';
import closeIcn from '../../images/icn-close.svg';
import arrowIcon from '../../images/icn-chevron-right-black.svg';
import useShowMoreUtil from '../../utils/hooks/useShowMoreUtil';

const cookiesInfo = [
  {
    title: 'Essential Cookies',
    text: 'These cookies are essential to provide you with services available through the website and to enable you to use some of its features. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.',
    type: 'essential',
  },
  {
    title: 'Functionality Cookies',
    text: 'These cookies allow us to remember choices you make when you use the website, such as language preference. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the website. Without these cookies, certain utilities will be missing from the site.',
    type: 'functional',
  },
  {
    title: 'Tracking and Performance Cookies',
    text: 'These cookies are used to track information about traffic to the website and how users use the website. The information gathered via these cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these cookies to test new pages, features or new functionality of the website to see how our users react to them.',
    type: 'analytics',
  },
];

const GDPRForm = ({ onSubmit }) => {
  const [preferences, setPreferences] = useState({
    functional: true,
    analytics: true,
  });
  const showMoreEssentialTextUtil = useShowMoreUtil();
  const showMoreFunctionalTextUtil = useShowMoreUtil();
  const showMoreAnalyticsTextUtil = useShowMoreUtil();

  const showMoreHooks = {
    essential: showMoreEssentialTextUtil,
    functional: showMoreFunctionalTextUtil,
    analytics: showMoreAnalyticsTextUtil,
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    onSubmit(preferences);
  };

  return (
    <form onSubmit={handleFormSubmit} className={styles.section}>
      <h3 className={styles.subheading}>Manage cookie preferences</h3>
      <p className={styles.text}>
        Cookies can be "persistent" or "session" cookies. Persistent cookies
        remain on your personal computer or mobile device when you go offline,
        while session cookies are deleted as soon as you close your web browser.
        You can learn more about cookies here:{' '}
        <BaseLink
          className={styles.link}
          href="https://www.termsfeed.com/blog/cookies/"
        >
          Cookies by TermsFeed Generator.
        </BaseLink>{' '}
        We use both session and persistent cookies for the purposes set out
        below:
      </p>
      <ul className={styles.cookies}>
        {cookiesInfo.map(({ title, text, type }, i) => {
          const showMoreUtil = showMoreHooks[type];
          return (
            <li key={i}>
              <div className={styles.accordian}>
                <button
                  onClick={() => showMoreUtil.toggle()}
                  className={cn(
                    styles.link,
                    styles.accordian__dropdownBtn,
                    showMoreUtil.isCollapsed &&
                      styles.accordian__dropdownBtn_collapsed
                  )}
                  type="button"
                >
                  <span className="sr-only">
                    {showMoreUtil.isCollapsed ? 'Show more' : 'Show less'}
                  </span>
                  <Icon widthPixelSizes={[16, 16, 16]} icon={arrowIcon} />
                </button>
                {type === 'essential' ? (
                  <div className={styles.accordian__checkboxLabel}>
                    <p className={styles.accordian__checkboxText}>{title}</p>
                    <p className={styles.accordian__checkboxHighlight}>
                      Always Active
                    </p>
                  </div>
                ) : (
                  <label
                    for={`${type}-id`}
                    className={styles.accordian__checkboxLabel}
                  >
                    <span className={styles.accordian__checkboxText}>
                      {title}
                    </span>
                    <input
                      id={`${type}-id`}
                      name={type}
                      className="sr-only"
                      type="checkbox"
                      defaultChecked
                      onChange={(e) =>
                        setPreferences({
                          ...preferences,
                          [type]: e.target.checked,
                        })
                      }
                    />
                    <span className={styles.accordian__checkboxSlider}></span>
                  </label>
                )}
              </div>
              <p
                className={cn(
                  styles.text,
                  showMoreUtil.isCollapsed && 'hidden'
                )}
              >
                {text}
              </p>
            </li>
          );
        })}
      </ul>
      <Button
        type="light-blue"
        className={styles.btn}
        buttonProps={{ type: 'submit' }}
      >
        Save my preferences
      </Button>
    </form>
  );
};

export default function GDPRPopup({ close, onSubmit }) {
  const showMoreTextUtil = useShowMoreUtil();

  return (
    <div tabIndex={1} className={styles.popup}>
      <button className={styles.closeBtn} onClick={close}>
        <Icon widthPixelSizes={[16, 16, 24]} icon={closeIcn} />
      </button>
      <h2 className={styles.heading}>Tracking Technologies and Cookies</h2>
      <div className={styles.content}>
        <div className={styles.section}>
          <p
            className={cn(
              styles.text,
              showMoreTextUtil.isCollapsed && styles.text_collapsed
            )}
          >
            We use cookies and similar tracking technologies to track the
            activity on our service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
            <ul>
              <li>
                Cookies or browser cookies. A cookie is a small file placed on
                your device. You can instruct Your browser to refuse all cookies
                or to indicate when a cookie is being sent. However, if You do
                not accept cookies, You may not be able to use some parts of our
                Service. Unless you have adjusted Your browser setting so that
                it will refuse cookies, our service may use cookies.
              </li>
              <li>
                Flash cookies. Certain features of our service may use local
                stored objects (or flash cookies) to collect and store
                information about your preferences or your activity on our
                service. Flash cookies are not managed by the same browser
                settings as those used for Browser cookies. For more information
                on how you can delete flash cookies, please read "Where can I
                change the settings for disabling, or deleting local shared
                objects?" available at{' '}
                <BaseLink
                  className={styles.link}
                  href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                >
                  https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </BaseLink>
              </li>
              <li>
                Web Beacons. Certain sections of our service and our emails may
                contain small electronic files known as web beacons (also
                referred to as clear gifs, pixel tags, and single-pixel gifs)
                that permit the company, for example, to count users who have
                visited those pages or opened an email and for other related
                website statistics (for example, recording the popularity of a
                certain section and verifying system and server integrity).
              </li>
            </ul>
          </p>
          <button
            className={cn(
              styles.link,
              styles.showBtn,
              showMoreTextUtil.isCollapsed && styles.showBtn_withCollapsedText
            )}
            onClick={showMoreTextUtil.toggle}
          >
            {showMoreTextUtil.isCollapsed ? 'Show more' : 'Show less'}
          </button>
          <Button
            type="light-blue"
            className={styles.btn}
            onClick={() => onSubmit()}
          >
            Allow all Cookies
          </Button>
        </div>
        <GDPRForm onSubmit={onSubmit} />
      </div>
    </div>
  );
}
