import React from 'react';
import ReactJsPopup from 'reactjs-popup';
import { FocusOn } from 'react-focus-on';

function Popup({
  ariaLabel,
  isVisible,
  onClose,
  getContents,
  className,
  shards = [],
}) {
  return (
    <ReactJsPopup
      open={isVisible}
      modal
      nested
      onClose={onClose}
      ariaLabel={ariaLabel}
      className={className}
    >
      {(close) => (
        <FocusOn shards={shards} enabled={isVisible}>
          {getContents(close)}
        </FocusOn>
      )}
    </ReactJsPopup>
  );
}

export default Popup;
