import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './SectionTitle.scss';
import Title from '../Title/Title';

export default function SectionTitle({
  title,
  subtitle,
  children,
  className,
  hideSectionLine,
  sectionLineTheme = 'blue',
}) {
  return (
    <div className={cn('sectiontitle', className)}>
      <div
        className={cn(
          'sectiontitle__line',
          hideSectionLine && 'opacity-0',
          sectionLineTheme === 'blue'
            ? 'bg-blue-green-gradient'
            : 'bg-orange-purple-gradient'
        )}
      ></div>
      <Title size="m" title={title} />
      {subtitle && <h3 className="sectiontitle__subtitle">{subtitle}</h3>}
      {children && <div className="sectiontitle__text">{children}</div>}
    </div>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  hideSectionLine: PropTypes.bool,
  sectionLineTheme: PropTypes.oneOf(['orange', 'blue']),
};
