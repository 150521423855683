// extracted by mini-css-extract-plugin
export var wrapper = "NavBar-module--wrapper--pRP2V";
export var navBar = "NavBar-module--navBar--mPXcS";
export var navBar__items = "NavBar-module--navBar__items--MXfst";
export var navBar__connectItems = "NavBar-module--navBar__connectItems--Rf8VE";
export var navBar__connectItem = "NavBar-module--navBar__connectItem--uah+i";
export var navBar__menuTextItems = "NavBar-module--navBar__menuTextItems--N3wI3";
export var navBar__menuTextItem = "NavBar-module--navBar__menuTextItem--PzWh-";
export var navBar__menuText = "NavBar-module--navBar__menuText--tfW26";
export var navBar__dropdownBtn = "NavBar-module--navBar__dropdownBtn--Uym0w";
export var navBar__dropdownItems = "NavBar-module--navBar__dropdownItems--gkYKf";
export var navBar__dropdownItems_visible = "NavBar-module--navBar__dropdownItems_visible--HnjjL";
export var navBar__product = "NavBar-module--navBar__product--6VqOo";
export var navBar__productTitle = "NavBar-module--navBar__productTitle--n2Kan";
export var navBar__productIcon = "NavBar-module--navBar__productIcon--07N-L";
export var navBar__productDescription = "NavBar-module--navBar__productDescription--gmpF8";
export var navBar__menuText_active = "NavBar-module--navBar__menuText_active--H9rio";
export var navBar__dropdown_name_other = "NavBar-module--navBar__dropdown_name_other--NoQtW";
export var navBar__dropdown_name_solutions = "NavBar-module--navBar__dropdown_name_solutions--h0yh2";
export var navBar__dropdown_name_company = "NavBar-module--navBar__dropdown_name_company--dCEqp";
export var navBar__dropdown = "NavBar-module--navBar__dropdown--DlboC";
export var navBar__logoImg = "NavBar-module--navBar__logoImg--2NIXV";