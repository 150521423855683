import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './BaseLink.scss';

export default function BaseLink({
  href,
  disabled,
  isExternal = /^https?:\/\//.test(href),
  rel = isExternal ? 'noopener noreferrer' : undefined,
  target = isExternal ? '_blank' : undefined,
  children,
  className,
}) {
  if (disabled) {
    return (
      <span className={cn('base-link base-link_disabled', className)}>
        {children}
      </span>
    );
  }
  if (isExternal) {
    return (
      <a
        className={cn('base-link', className)}
        rel={rel}
        target={target}
        href={href}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      className={cn('base-link', className)}
      ref={rel}
      target={target}
      to={href}
    >
      {children}
    </Link>
  );
}

BaseLink.propTypes = {
  href: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isExternal: PropTypes.bool,
  rel: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
