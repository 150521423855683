import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import chevronDownIcon from '../../images/icn-chevron-down.svg';
import twitterIcon from '../../images/icn-twitter.svg';
import githubIcon from '../../images/icn-github.svg';
import youtubeIcon from '../../images/icn-youtube.svg';
import chevronUpIcon from '../../images/icn-chevron-top.svg';
import cmlIcon from '../../images/cml_icon-color--square_vector.svg';
import dvcIcon from '../../images/dvc_icon-color--square_vector.svg';
import studioIcon from '../../images/studio_icon-color--square_vector.svg';
import mlemIcon from '../../images/icn-mlem.svg';
import vsCodeIcon from '../../images/icons/vscode.svg';
import externalLinkIcon from '../../images/icn-external-link.svg';
import Icon from '../Icon/Icon';
import Logo from '../Logo/Logo';
import Alert from './Alert';
import {
  wrapper,
  navBar,
  navBar__logoImg,
  navBar__menuTextItem,
  navBar__menuTextItems,
  navBar__menuText,
  navBar__menuText_active,
  navBar__dropdown,
  navBar__dropdown_name_other,
  navBar__dropdown_name_solutions,
  navBar__dropdown_name_company,
  navBar__dropdownBtn,
  navBar__items,
  navBar__dropdownItems,
  navBar__dropdownItems_visible,
  navBar__product,
  navBar__productIcon,
  navBar__productTitle,
  navBar__productDescription,
  navBar__connectItems,
  navBar__connectItem,
} from './NavBar.module.scss';
import usePopup from '../../utils/hooks/usePopup';
import onSelect from '../../utils/onSelect';
import { careerUrl } from '../Career/Career';

export default function NavBar() {
  const productsPopup = usePopup();
  const solutionsPopup = usePopup();
  const otherPopup = usePopup();
  const companyPopup = usePopup();

  return (
    <div className={wrapper}>
      <Alert />
      <nav className={navBar}>
        <div className={navBar__logoImg}>
          <Logo size="small" link="/" linkDescription="Go to home page" />
        </div>
        <ul className={cn(navBar__items, navBar__connectItems)}>
          <li className={navBar__connectItem}>
            <Icon
              link="https://twitter.com/DVCorg"
              linkDescription="Go to Twitter"
              icon={twitterIcon}
              widthPixelSizes={[16, 16, 24]}
            />
          </li>
          <li className={navBar__connectItem}>
            <Icon
              link="https://github.com/iterative"
              linkDescription="Go to Github"
              icon={githubIcon}
              widthPixelSizes={[16, 16, 24]}
            />
          </li>
          <li className={navBar__connectItem}>
            <Icon
              link="https://www.youtube.com/channel/UC37rp97Go-xIX3aNFVHhXfQ"
              linkDescription="Go to Youtube"
              icon={youtubeIcon}
              widthPixelSizes={[16, 16, 24]}
            />
          </li>
        </ul>
        <ul className={cn(navBar__items, navBar__menuTextItems)}>
          <li className={navBar__menuTextItem}>
            <Link to="/why-iterative" className={navBar__menuText}>
              Why Iterative
            </Link>
          </li>
          <li className={cn(navBar__menuTextItem, 'hidden xs:flex')}>
            <div
              className={cn(navBar__dropdown, navBar__dropdown_name_solutions)}
              ref={solutionsPopup.containerEl}
              onMouseEnter={solutionsPopup?.open}
              onMouseLeave={solutionsPopup?.close}
            >
              <button
                onPointerUp={solutionsPopup.toggle}
                onKeyUp={onSelect(solutionsPopup?.toggle)}
                className={cn(
                  navBar__menuText,
                  navBar__dropdownBtn,
                  solutionsPopup.isOpen && navBar__menuText_active
                )}
              >
                Solutions{' '}
                <Icon
                  icon={solutionsPopup.isOpen ? chevronUpIcon : chevronDownIcon}
                  widthPixelSizes={[8, 10, 13]}
                />{' '}
              </button>
              <ul
                className={cn(
                  navBar__items,
                  navBar__dropdownItems,
                  solutionsPopup.isOpen && navBar__dropdownItems_visible
                )}
              >
                <li className={cn(navBar__menuTextItem, 'flex')}>
                  <Link
                    activeClassName={navBar__menuText_active}
                    to="/model-registry"
                    className={navBar__menuText}
                  >
                    Model Registry
                  </Link>
                </li>
                <li className={cn(navBar__menuTextItem, 'flex')}>
                  <Link
                    activeClassName={navBar__menuText_active}
                    to="/experiment-tracking"
                    className={navBar__menuText}
                  >
                    Experiment Tracking
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={cn(navBar__menuTextItem, 'flex order-1 md:order-none')}
          >
            <div
              className={navBar__dropdown}
              ref={productsPopup.containerEl}
              onMouseEnter={productsPopup?.open}
              onMouseLeave={productsPopup?.close}
            >
              <button
                onPointerUp={productsPopup?.toggle}
                onKeyUp={onSelect(productsPopup?.toggle)}
                className={cn(
                  navBar__menuText,
                  navBar__dropdownBtn,
                  productsPopup.isOpen && navBar__menuText_active
                )}
              >
                Products{' '}
                <Icon
                  icon={productsPopup.isOpen ? chevronUpIcon : chevronDownIcon}
                  widthPixelSizes={[8, 10, 13]}
                />{' '}
              </button>
              <ul
                className={cn(
                  navBar__items,
                  navBar__dropdownItems,
                  productsPopup.isOpen && navBar__dropdownItems_visible
                )}
              >
                <li>
                  <a
                    className={navBar__product}
                    href="https://studio.iterative.ai"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={navBar__productIcon}>
                      <Icon widthPixelSizes={[32, 24, 32]} icon={studioIcon} />
                    </div>
                    <h2 className={navBar__productTitle}>
                      Studio
                      <Icon
                        widthPixelSizes={[16, 16, 16]}
                        icon={externalLinkIcon}
                      />
                    </h2>
                    <p className={navBar__productDescription}>
                      Track and share insights from ML projects
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    className={navBar__product}
                    href="https://dvc.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={navBar__productIcon}>
                      <Icon widthPixelSizes={[32, 24, 32]} icon={dvcIcon} />
                    </div>
                    <h2 className={navBar__productTitle}>
                      DVC
                      <Icon
                        widthPixelSizes={[16, 16, 16]}
                        icon={externalLinkIcon}
                      />
                    </h2>
                    <p className={navBar__productDescription}>
                      Open-source version control system for ML projects
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    className={navBar__product}
                    href="https://marketplace.visualstudio.com/items?itemName=Iterative.dvc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={navBar__productIcon} />
                    <h2 className={navBar__productTitle}>
                      VS Code Extension{' '}
                      <img
                        width="16"
                        height="16"
                        src={vsCodeIcon}
                        alt="VS Code logo"
                      />
                      <Icon
                        widthPixelSizes={[16, 16, 16]}
                        icon={externalLinkIcon}
                      />
                    </h2>
                    <p className={navBar__productDescription}>
                      Local ML model development and experiment tracking
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    className={navBar__product}
                    href="https://cml.dev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={navBar__productIcon}>
                      <Icon widthPixelSizes={[32, 24, 32]} icon={cmlIcon} />
                    </div>
                    <h2 className={navBar__productTitle}>
                      CML
                      <Icon
                        widthPixelSizes={[16, 16, 16]}
                        icon={externalLinkIcon}
                      />
                    </h2>
                    <p className={navBar__productDescription}>
                      Open-source CI/CD for ML projects
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    className={navBar__product}
                    href="https://mlem.ai"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={navBar__productIcon}>
                      <Icon widthPixelSizes={[32, 24, 32]} icon={mlemIcon} />
                    </div>
                    <h2 className={navBar__productTitle}>
                      MLEM
                      <Icon
                        widthPixelSizes={[16, 16, 16]}
                        icon={externalLinkIcon}
                      />
                    </h2>
                    <p className={navBar__productDescription}>
                      Open-source model registry and deployment tool for ML
                      projects
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className={cn(navBar__menuTextItem, 'hidden lg:flex')}>
            <div
              className={cn(navBar__dropdown, navBar__dropdown_name_company)}
              ref={companyPopup.containerEl}
              onMouseEnter={companyPopup?.open}
              onMouseLeave={companyPopup?.close}
            >
              <button
                onPointerUp={companyPopup.toggle}
                onKeyUp={onSelect(companyPopup?.toggle)}
                className={cn(
                  navBar__menuText,
                  navBar__dropdownBtn,
                  companyPopup.isOpen && navBar__menuText_active
                )}
              >
                Company{' '}
                <Icon
                  icon={companyPopup.isOpen ? chevronUpIcon : chevronDownIcon}
                  widthPixelSizes={[8, 10, 13]}
                />{' '}
              </button>
              <ul
                className={cn(
                  navBar__items,
                  navBar__dropdownItems,
                  companyPopup.isOpen && navBar__dropdownItems_visible
                )}
              >
                <li className={cn(navBar__menuTextItem, 'flex')}>
                  <Link
                    activeClassName={navBar__menuText_active}
                    to="/about"
                    className={navBar__menuText}
                  >
                    About Us
                  </Link>
                </li>
                <li className={cn(navBar__menuTextItem, 'flex')}>
                  <a
                    href={careerUrl}
                    className={navBar__menuText}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Career
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className={cn(navBar__menuTextItem, 'hidden lg:flex')}>
            <a
              href="https://learn.iterative.ai/"
              className={navBar__menuText}
              rel="noopener noreferrer"
              target="_blank"
            >
              Course
            </a>
          </li>
          <li className={cn(navBar__menuTextItem, 'hidden lg:flex')}>
            <Link
              activeClassName={navBar__menuText_active}
              to="/pricing"
              className={navBar__menuText}
            >
              Pricing
            </Link>
          </li>
          <li className={cn(navBar__menuTextItem, 'flex order-2 lg:hidden')}>
            <div
              className={cn(navBar__dropdown, navBar__dropdown_name_other)}
              ref={otherPopup.containerEl}
              onMouseEnter={otherPopup?.open}
              onMouseLeave={otherPopup?.close}
            >
              <button
                onPointerUp={otherPopup.toggle}
                onKeyUp={onSelect(otherPopup?.toggle)}
                className={cn(
                  navBar__menuText,
                  navBar__dropdownBtn,
                  otherPopup.isOpen && navBar__menuText_active
                )}
              >
                ...
              </button>
              <ul
                className={cn(
                  navBar__items,
                  navBar__dropdownItems,
                  otherPopup.isOpen && navBar__dropdownItems_visible
                )}
              >
                <li className={cn(navBar__menuTextItem, 'flex xs:hidden')}>
                  <Link
                    activeClassName={navBar__menuText_active}
                    to="/model-registry"
                    className={navBar__menuText}
                  >
                    Model Registry
                  </Link>
                </li>
                <li className={cn(navBar__menuTextItem, 'flex xs:hidden')}>
                  <Link
                    activeClassName={navBar__menuText_active}
                    to="/experiment-tracking"
                    className={navBar__menuText}
                  >
                    Experiment Tracking
                  </Link>
                </li>
                <li className={cn(navBar__menuTextItem, 'flex')}>
                  <Link
                    activeClassName={navBar__menuText_active}
                    to="/about"
                    className={navBar__menuText}
                  >
                    About Us
                  </Link>
                </li>
                <li className={cn(navBar__menuTextItem, 'flex')}>
                  <a
                    href={careerUrl}
                    className={navBar__menuText}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Career
                  </a>
                </li>
                <li className={cn(navBar__menuTextItem, 'flex lg:hidden')}>
                  <a
                    href="https://learn.iterative.ai/"
                    className={navBar__menuText}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Course
                  </a>
                </li>
                <li className={cn(navBar__menuTextItem, 'flex')}>
                  <Link
                    activeClassName={navBar__menuText_active}
                    to="/pricing"
                    className={navBar__menuText}
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
