import { useState, useRef } from 'react';
import useOnClickOutside from './useOnClickOutside';
import useOnEscape from './useOnEscape';

const usePopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const containerEl = useRef(null);

  const close = () => {
    setIsOpen(false);
  };

  useOnClickOutside(containerEl, close);
  useOnEscape(close);

  const open = () => {
    setIsOpen(true);
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  return { toggle, containerEl, isOpen, open, close };
};

export default usePopup;
