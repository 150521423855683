import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { GDPRContext } from '../GDPR/Provider';

const Analytics = () => {
  const { userPreferences } = useContext(GDPRContext);

  useEffect(() => {
    const hubspotScript = document.querySelector('#hs-script-loader');
    const zoomInfoNoScript = document.querySelector('#zoom-info-noscript');
    const salesLoftScript = document.querySelector('#salesloft-script-loader');

    if (userPreferences.analytics && !hubspotScript) {
      const script = document.createElement('script');
      script.src = '//js.hs-scripts.com/21087317.js';
      script.id = 'hs-script-loader';
      script.type = 'text/javascript';
      script.async = true;
      document.body.appendChild(script);
    }

    if (userPreferences.analytics && !zoomInfoNoScript) {
      const noScript = document.createElement('noscript');
      const img = document.createElement('img');
      img.width = 1;
      img.height = 1;
      img.style = 'display: none;';
      img.alt = 'websights';
      img.id = 'zoom-info-noscript';
      img.src = 'https://ws.zoominfo.com/pixel/622b6188df6426001354c923';
      noScript.appendChild(img);
      document.body.appendChild(noScript);
    }

    if (userPreferences.analytics && !salesLoftScript) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'salesloft-script-loader';
      script.innerHTML = `
      (function(i,s,o,g,r,a,m){i['SLScoutObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://scout-cdn.salesloft.com/sl.js','slscout');
        slscout(["init", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0IjoxMDYxODR9.0cOFsFno_77YlXezpLXMWFR1zPO0fBZQ_77GxZtyGH4"]);
      `;
      document.body.appendChild(script);
    }
  }, [userPreferences]);

  return (
    <Helmet>
      <script
        async
        defer
        data-domain="iterative.ai"
        src="https://dvc.org/pl/js/script.js"
        data-api="https://dvc.org/pl/api/event"
      ></script>
      {userPreferences.analytics && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_ID}`}
        ></script>
      )}
      {userPreferences.analytics && (
        <script type="text/javascript">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.GATSBY_GA_ID}');`}
        </script>
      )}
      {userPreferences.analytics && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ADS_ID}`}
        ></script>
      )}
      {userPreferences.analytics && (
        <script type="text/javascript">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.GATSBY_GOOGLE_ADS_ID}');`}
        </script>
      )}
      {userPreferences.analytics && (
        <script type="text/javascript">
          {`
            (function() {
            var zi = document.createElement('script');
            zi.type = 'text/javascript';
            zi.async = true;
            zi.referrerPolicy = 'unsafe-url';
            zi.src = 'https://ws.zoominfo.com/pixel/622b6188df6426001354c923';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(zi, s);
              })();`}
        </script>
      )}
    </Helmet>
  );
};

export default Analytics;
