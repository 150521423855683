import React, { useContext, useState, useRef } from 'react';
import Banner from '../GDPRBanner/GDPRBanner';
import Popup from '../Popup/Popup';
import GDPRPopup from '../GDPRPopup/GDPRPopup';
import { GDPRContext } from './Provider';

function GDPR() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);
  const { hasUserConsented, loading, setUserPreferences } =
    useContext(GDPRContext);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSubmit = (preferences) => {
    setIsPopupOpen(false);
    setUserPreferences(preferences);
  };

  return (
    <>
      <Banner
        show={!isPopupOpen && !loading && !hasUserConsented}
        onAccept={handleSubmit}
        onManage={openPopup}
        invisible={isPopupOpen}
      />
      <Popup
        onClose={closePopup}
        isVisible={isPopupOpen}
        ariaLabel="Cookie info and management"
        className="gdpr-popup"
        shards={[popupRef]}
        getContents={(close) => (
          <div ref={popupRef}>
            <GDPRPopup onSubmit={handleSubmit} close={close} />
          </div>
        )}
      />
    </>
  );
}

export default GDPR;
