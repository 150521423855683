import React from 'react';
import Icon from '../Icon/Icon';
import Logo from '../Logo/Logo';
import FooterList from '../FooterList/FooterList';
import { careerUrl } from '../Career/Career';
import githubIcon from '../../images/icn-github.svg';
import twitterIcon from '../../images/icn-twitter.svg';
import linkedInIcon from '../../images/icn-linkedin.svg';
import youtubeIcon from '../../images/icn-youtube.svg';
import discordIcon from '../../images/icn-discord.svg';
import dvcIcon from '../../images/dvc_icon-color--square_vector.svg';
import cmlIcon from '../../images/cml_icon-color--square_vector.svg';
import studioIcon from '../../images/studio_icon-color--square_vector.svg';
import mlemIcon from '../../images/icn-mlem.svg';
import './Footer.scss';

export default function Footer() {
  const footerLinkListsData = [
    {
      title: 'Company',
      links: [
        { url: '/about', isLocal: true, text: 'About us' },
        { url: '/pricing', isLocal: true, text: 'Pricing' },
        {
          url: careerUrl,
          isLocal: false,
          text: 'Join us',
        },
        { url: '/brand', isLocal: true, text: 'Media Kit' },
      ],
    },
    {
      title: 'Legal',
      links: [
        {
          url: 'https://iterative.notion.site/Privacy-Cookie-Policy-edbce9b3b3d14f26950b7dca617b2033',
          isLocal: false,
          text: 'Privacy Policy',
        },
        {
          url: 'https://iterative.notion.site/Privacy-Cookie-Policy-edbce9b3b3d14f26950b7dca617b2033',
          isLocal: false,
          text: 'Terms of use',
        },
        {
          url: '/brand',
          isLocal: true,
          text: 'Brand and Assets',
        },
        {
          url: '/security-and-privacy',
          isLocal: true,
          text: 'Security and Privacy',
        },
      ],
    },
    {
      title: 'Products',
      links: [
        { url: 'https://dvc.org', text: 'DVC', icon: dvcIcon },
        { url: 'https://cml.dev', text: 'CML', icon: cmlIcon },
        {
          url: 'https://studio.iterative.ai',
          text: 'Studio',
          icon: studioIcon,
        },
        {
          url: 'https://mlem.ai',
          text: 'MLEM',
          icon: mlemIcon,
        },
        {
          url: 'https://marketplace.visualstudio.com/items?itemName=Iterative.dvc',
          text: 'VS Code Extension',
          icon: dvcIcon,
        },
      ],
    },
  ];
  return (
    <footer className="footer">
      <ul className="footer__list footer__link-lists">
        {footerLinkListsData.map((data, i) => (
          <FooterList key={i} {...data} />
        ))}
      </ul>
      <div className="footer__company">
        <Logo link="/" linkDescription="Go to home page" />
        <p className="footer__company-text">
          Open platform to operationalize AI
        </p>
      </div>
      <ul className="footer__list footer__social-links-list">
        <li className="footer__social-link-list-item">
          <Icon
            link="https://github.com/iterative"
            linkDescription="Go to Github"
            icon={githubIcon}
            widthPixelSizes={[16, 24, 24]}
          />
        </li>
        <li className="footer__social-link-list-item">
          <Icon
            link="https://twitter.com/DVCorg"
            linkDescription="Go to Twitter"
            icon={twitterIcon}
            widthPixelSizes={[16, 24, 24]}
          />
        </li>
        <li className="footer__social-link-list-item">
          <Icon
            link="https://www.linkedin.com/company/iterative-ai"
            linkDescription="Go to LinkedIn"
            icon={linkedInIcon}
            widthPixelSizes={[16, 24, 24]}
          />
        </li>
        <li className="footer__social-link-list-item">
          <Icon
            link="https://www.youtube.com/channel/UC37rp97Go-xIX3aNFVHhXfQ"
            linkDescription="Go to YouTube"
            icon={youtubeIcon}
            widthPixelSizes={[16, 24, 24]}
          />
        </li>
        <li className="footer__social-link-list-item">
          <Icon
            link="https://dvc.org/chat"
            linkDescription="Go to Discord"
            icon={discordIcon}
            widthPixelSizes={[16, 24, 24]}
          />
        </li>
      </ul>
    </footer>
  );
}
