import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import defaultSocialImage from '../../images/social-card.png';

const SEO = ({
  title,
  description,
  image,
  imageHeight,
  imageWidth,
  imageAlt,
  article,
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata;
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultSocialImage}`,
    imageWidth: imageWidth || 2400,
    imageHeight: imageHeight || 1260,
    imageAlt:
      imageAlt ||
      'The Iterative company logo next to the word &quot;iterative&quot; in front of a blue background.',
    url: `${siteUrl}${pathname}`,
  };
  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      defaultTitle={defaultTitle}
    >
      <html lang="en" />
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:site_name" content="Iterative" />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {article ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.imageWidth && (
        <meta property="og:image:width" content={seo.imageWidth} />
      )}
      {seo.imageHeight && (
        <meta property="og:image:height" content={seo.imageHeight} />
      )}
      {seo.imageAlt && <meta name="og:image:alt" content={seo.imageAlt} />}
      <meta property="og:image:type" content="image/png" />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:site" content={twitterUsername} />
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {seo.imageAlt && <meta name="twitter:image:alt" content={seo.imageAlt} />}
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0f1624" />
    </Helmet>
  );
};
export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        twitterUsername
      }
    }
  }
`;
