import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './Button.scss';

export default function ButtonLink({
  href,
  type = 'blue-gradient',
  children,
  size = 'default',
  external,
  rel = external ? 'noreferrer noopener' : undefined,
  target = external ? '_blank' : undefined,
  className,
}) {
  return (
    <a
      className={cn(
        'button',
        `button_size_${size}`,
        `button_color_${type}`,
        'button_link',
        className
      )}
      href={href}
      rel={rel}
      target={target}
    >
      {children}
    </a>
  );
}

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'blue-gradient',
    'orange-gradient',
    'light-blue',
    'black',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'default']),
  external: PropTypes.bool,
  href: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
};
