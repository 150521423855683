// extracted by mini-css-extract-plugin
export var popup = "GDPRPopup-module--popup--WjAFs";
export var content = "GDPRPopup-module--content--5a1Ev";
export var closeBtn = "GDPRPopup-module--closeBtn--rOuYC";
export var section = "GDPRPopup-module--section--+7G43";
export var heading = "GDPRPopup-module--heading--0JXrb";
export var link = "GDPRPopup-module--link--9GCGX";
export var text = "GDPRPopup-module--text--3EGVo";
export var text_collapsed = "GDPRPopup-module--text_collapsed--BPnfM";
export var showBtn = "GDPRPopup-module--showBtn--beqb5";
export var showBtn_withCollapsedText = "GDPRPopup-module--showBtn_withCollapsedText--5B2GY";
export var btn = "GDPRPopup-module--btn--51itQ";
export var subheading = "GDPRPopup-module--subheading--ZhDmn";
export var cookies = "GDPRPopup-module--cookies--Pt13M";
export var accordian = "GDPRPopup-module--accordian--59HEk";
export var accordian__dropdownBtn = "GDPRPopup-module--accordian__dropdownBtn--eSP+h";
export var accordian__dropdownBtn_collapsed = "GDPRPopup-module--accordian__dropdownBtn_collapsed--UNfi8";
export var accordian__checkboxLabel = "GDPRPopup-module--accordian__checkboxLabel--OWXLu";
export var accordian__checkboxText = "GDPRPopup-module--accordian__checkboxText--hoCyr";
export var accordian__checkboxHighlight = "GDPRPopup-module--accordian__checkboxHighlight--4fKmm";
export var accordian__checkboxSlider = "GDPRPopup-module--accordian__checkboxSlider--noasD";