import React from 'react';
import BaseLink from '../../BaseLink/BaseLink';

import * as styles from './index.module.scss';

const Alert = () => (
  <div className={styles.alert}>
    <div className={styles.text}>
      <span className={styles.icon} role="img" aria-label="rocket">
        🚀
      </span>{' '}
      <p>
        New Release!{' '}
        <BaseLink href="https://dvc.org/blog/iterative-studio-model-registry">
          Git-backed Machine Learning Model Registry
        </BaseLink>{' '}
        for all your model management needs.
      </p>
    </div>
  </div>
);

export default Alert;
