import { useEffect } from 'react';

const events = [`mousedown`, `touchstart`];

const useOnClickOutside = (ref, onClickOutside, cleanup = true) => {
  useEffect(() => {
    const isOutside = (element) =>
      !ref.current || !ref.current.contains(element);

    const onClick = (event) => {
      if (isOutside(event.target)) {
        onClickOutside(event);
        if (cleanup) {
          for (const eventName of events)
            document.removeEventListener(eventName, onClick);
        }
      }
    };
    for (const eventName of events) {
      document.addEventListener(eventName, onClick);
    }

    return () => {
      for (const eventName of events)
        document.removeEventListener(eventName, onClick);
    };
  }, [ref, onClickOutside, cleanup]);
};
export default useOnClickOutside;
