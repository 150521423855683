import * as React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import iterativeIcon from '../../images/iterative_icon-color--square_vector.svg';
import iterativeText from '../../images/iterative-text.png';
import {
  logo,
  logo_size_small,
  logo_active,
  logo_isLink,
  logo__visuallyHiddenEl,
} from './Logo.module.scss';

export default function Logo({ link, linkDescription, size = 'standard' }) {
  const logoChildren = (
    <>
      <Icon
        icon={iterativeIcon}
        widthPixelSizes={[size === 'small' ? 24 : 40, 40, 40]}
      />
      <Icon
        icon={iterativeText}
        widthPixelSizes={[size === 'small' ? 82 : 132, 132, 132]}
        heightPixelSizes={[size === 'small' ? 18 : 28, 28, 28]}
      />
      <span className={logo__visuallyHiddenEl}>{linkDescription}</span>
    </>
  );

  return (
    <>
      {link ? (
        <Link
          activeClassName={logo_active}
          to={link}
          className={cn(logo, logo_isLink, size === 'small' && logo_size_small)}
        >
          {logoChildren}
        </Link>
      ) : (
        <div className={cn(logo, size === 'small' && logo_size_small)}>
          {logoChildren}
        </div>
      )}
    </>
  );
}

Logo.propTypes = {
  link: PropTypes.string,
  linkDescription: PropTypes.string,
  size: PropTypes.oneOf(['small']),
};
