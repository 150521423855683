import { useState } from 'react';

const useShowMoreUtil = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return { isCollapsed, toggle };
};

export default useShowMoreUtil;
